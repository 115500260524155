<template>
  <div class="topecharts">
    <!-- 背景图 -->
    <div class="query">
      <a class="btn" @click="chaday(1)" :class="{active:dynamic==1}">按天查询</a>
      <a class="btn-secondary" @click="yearsday(3)" :class="{active:dynamic==3}"> 按年查询
      </a>
    </div>
    <div class="img1"></div>
    <div class="text">
      待处理事项
    </div>
    <div class="img2"></div>
    <div class="main">
      <div class="main1">
        <div class="kehugood">
          <div class="text">本年充值金额</div>
          <div class="num">{{kunum}}元</div>
        </div>
      </div>
      <div class="main2">
        <div class="main2_one">
          <div class="num">
            {{num}}
          </div>
          <div class="text">
            元
          </div>
          <div class="tishi">
            总充值金额
          </div>
        </div>
        <div class="main2_two">
          <div class="num">
            {{num1}}
          </div>
          <div class="text">
            元
          </div>
          <div class="tishi">
            小程序充值
          </div>
        </div>
        <div class="main2_three">
          <div class="num">
            {{num2}}
          </div>
          <div class="text">
            元
          </div>
          <div class="tishi">
            微信充值
          </div>
        </div>
        <div class="main2_four">
          <div class="num">
            {{num3}}
          </div>
          <div class="text">
            元
          </div>
          <div class="tishi">
            公众号充值
          </div>
        </div>
        <div class="main2_five">
          <div class="num">
            {{num4}}
          </div>
          <div class="text">
            元
          </div>
          <div class="tishi">
            系统充值
          </div>
        </div>
        <div class="main2_six">
          <div class="num">
            {{num5}}
          </div>
          <div class="text">
            元
          </div>
          <div class="tishi">
            支付宝充值
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    time
  } from 'echarts';
  export default {
    data() {
      return {
        kunum: 2222,
        num: 30, //总充值金额
        num1: 222, //小程序充值
        num2: 345, //微信充值
        num3: 0, //公众号充值
        num4: 2, //支付宝
        num5: 123, //系统
        time: '',
        dynamic: 3,
        Timer: ''
      }
    },
    methods: {
      gethomeMessage() {
        let Authorization = window.sessionStorage.getItem("Authorization");
        this.$axios.get("/user/homeMessage", {
          headers: {
            'token': Authorization
          }
        }).then((res) => {
          if (res.data.code == 401) {
            this.$router.push('/')
              
          } else {
            this.kunum = res.data.data.yearRevenue
          }

        })
      },

      getDate() {
        var date = new Date();
        var year = date.getFullYear(); //  返回的是年份
        var month = date.getMonth() + 1; //  返回的月份上个月的月份，记得+1才是当月
        var dates = date.getDate(); //  返回的是几号
        var hours = date.getHours();
        var min = date.getMinutes();
        var sec = date.getSeconds();
        this.time = year + "-" + month + "-" + dates + "+" + hours + ":" + min + ":" + sec
      },
      payMember() {
        let Authorization = window.sessionStorage.getItem("Authorization");
        this.$axios.get('/cash/payMember?time=' + this.time + '&flag=' + 3, {
          headers: {
            'token': Authorization
          }
        }).then((res) => {
          if (res.data.code == 401) {
            this.$router.push('/')
              
          } else {
            var data = res.data.data
            this.num = data.sumMoney //总充值金额
            this.num1 = data.weiXinGZHMoney //公众号充值
            this.num2 = data.weiXinMoney //微信充值\
            this.num3 = data.weiXinXCXMoney // 小程序充值
            this.num4 = data.xiTongMoney //系统
            this.num5 = data.zhiFuBaoMoney //支付宝  
          }

        })
      },
      chaday(e) {
        this.dynamic = e
        let Authorization = window.sessionStorage.getItem("Authorization");
        this.$axios.get('/cash/payMember?time=' + this.time + '&flag=' + 1, {
          headers: {
            'token': Authorization
          }
        }).then((res) => {
          if (res.data.code == 401) {
            this.$router.push('/')
              
          } else {
            var data = res.data.data
            this.num = data.sumMoney //总充值金额
            this.num1 = data.weiXinGZHMoney //公众号充值
            this.num2 = data.weiXinMoney //微信充值\
            this.num3 = data.weiXinXCXMoney // 小程序充值
            this.num4 = data.xiTongMoney //系统
            this.num5 = data.zhiFuBaoMoney //支付宝  
          }

        })
      },
      yearsday(e) {
        this.dynamic = e
        this.payMember()
      }
    },
    mounted() {
      this.gethomeMessage()
      this.payMember()
      this.Timer = setInterval(this.payMember, 12000)
      this.Timer = setInterval(this.gethomeMessage, 12000)
    },
    created() {
      this.getDate()
    },
    destroyed() {
      clearInterval(this.Timer)
    }
  }
</script>

<style scoped lang="less">
  .TOPECHARTS {
    width: 100%;
    height: 100%;
    position: relative;

  }

  .img1 {
    width: 33%;
    height: 10px;
    background-image: url('../../assets/img/rightciji.png');
    background-size: 100%;
    background-repeat: no-repeat;
    position: absolute;
    left: -13px;
    top: 39px;
  }

  .img2 {
    width: 33%;
    height: 10px;
    background-image: url('../../assets/img/leftci.png');
    background-size: 100%;
    background-repeat: no-repeat;
    position: absolute;
    left: 360px;
    top: 39px;
    z-index: -1;

  }

  .text {
    color: white;
    font-size: 30px;
    text-align: center;
  }

  .main {
    width: 100%;
    height: 366px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
  }

  .main1 {
    width: 100%;
    height: 30%;
    background-image: url('../../assets/img/xzuo.png');
    background-repeat: no-repeat;
    background-size: 100%;
    position: absolute;
    top: 20px;


  }

  .kehugood {
    width: 100%;
    height: 100%;
  }

  .kehugood .text {
    font-size: 30px;
    color: white;
    position: absolute;
    top: -15px;
    left: 0.625rem;

  }

  .kehugood .num {
    font-size: 30px;
    color: skyblue;
    position: absolute;
    top: -15px;
    width: 300px;
    left: 247px;

  }

  .main2 {
    width: 100%;
    height: 50%;
    display: flex;
    flex-wrap: wrap;
  }

  .main2 .main2_one {
    width: 33.3%;
    height: 100%;
    background-image: url('../../assets/img/sisbian.png');
    background-repeat: no-repeat;
    background-size: 100%;
  }

  .main2 .main2_one .num {
    color: skyblue;
    font-size: 23px;
    position: absolute;
    top: 184px;
    left: 17px;
  }

  .main2 .main2_one .text {
    color: #fff;
    font-size: 18px;
    position: absolute;
    top: 187px;
    left: 131px;
  }

  .main2 .main2_one .tishi {
    color: white;
    font-size: 23px;
    position: absolute;
    top: 144px;
    left: 27px;
  }

  .main2 .main2_two {
    width: 33.3%;
    height: 100%;
    background-image: url('../../assets/img/sisbian.png');
    background-repeat: no-repeat;
    background-size: 100%;
  }

  .main2 .main2_two .num {
    color: skyblue;
    font-size: 23px;
    position: absolute;
    top: 184px;
    left: 197px;
  }

  .main2 .main2_two .text {
    color: white;
    font-size: 18px;
    position: absolute;
    top: 187px;
    left: 291px;
  }

  .main2 .main2_two .tishi {
    color: white;
    font-size: 23px;
    position: absolute;
    top: 144px;
    left: 197px;
  }

  .main2 .main2_three {
    width: 33.3%;
    height: 100%;
    background-image: url('../../assets/img/sisbian.png');
    background-repeat: no-repeat;
    background-size: 100%;
  }

  .main2 .main2_three .num {
    color: skyblue;
    font-size: 23px;
    position: absolute;
    top: 184px;
    left: 387px;
  }

  .main2 .main2_three .text {
    color: white;
    font-size: 18px;
    position: absolute;
    top: 187px;
    left: 456px;
  }

  .main2 .main2_three .tishi {
    color: white;
    font-size: 23px;
    position: absolute;
    top: 144px;
    left: 381px;
  }

  .main2 .main2_four {
    width: 33.3%;
    height: 100%;
    background-image: url('../../assets/img/sisbian.png');
    background-repeat: no-repeat;
    background-size: 100%;
  }

  .main2 .main2_four .num {
    color: skyblue;
    font-size: 23px;
    position: absolute;
    top: 376px;
    left: 31px;
  }

  .main2 .main2_four .text {
    color: #fff;
    font-size: 18px;
    position: absolute;
    top: 376px;
    left: 131px;
  }

  .main2 .main2_four .tishi {
    color: white;
    font-size: 23px;
    position: absolute;
    top: 324px;
    left: 27px;
  }

  .main2 .main2_five {
    width: 33.3%;
    height: 100%;
    background-image: url('../../assets/img/sisbian.png');
    background-repeat: no-repeat;
    background-size: 100%;
  }

  .main2 .main2_five .num {
    color: skyblue;
    font-size: 23px;
    position: absolute;
    top: 371px;
    left: 205px;
  }

  .main2 .main2_five .text {
    color: #fff;
    font-size: 18px;
    position: absolute;
    top: 376px;
    left: 291px;
  }

  .main2 .main2_five .tishi {
    color: white;
    font-size: 23px;
    position: absolute;
    top: 324px;
    left: 209px;
  }

  .main2 .main2_six {
    width: 33.3%;
    height: 100%;
    background-image: url('../../assets/img/sisbian.png');
    background-repeat: no-repeat;
    background-size: 100%;
  }

  .main2 .main2_six .num {
    color: skyblue;
    font-size: 23px;
    position: absolute;
    top: 371px;
    left: 391px;
  }

  .main2 .main2_six .text {
    color: #fff;
    font-size: 18px;
    position: absolute;
    top: 376px;
    left: 465px;
  }

  .main2 .main2_six .tishi {
    color: white;
    font-size: 23px;
    position: absolute;
    top: 324px;
    left: 371px;
  }

  .query {
    width: 160px;
    display: flex;
    position: absolute;
    text-align: center;
    right: 18.5px;
    top: 184px;
    color: floralwhite;
    z-index: 9999;

    .btn {
      width: 50%;
      border: 1px solid #ccc;
      border-radius: 10px;
    }

    .btn-secondary {
      width: 50%;
      border: 1px solid #ccc;
      border-radius: 10px;
    }

    .active {
      background-color: lightskyblue;
    }
  }
  .num{
    width: 90px;
    text-align: center;
  }
</style>