<template>
  <div class="contontaab">
    <div :style="{ width: '250px', height: '250px' }" ref="myChart"></div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        Timer: '',
        messagE: ''
      }
    },
    methods: {

      getEchartData() {
        let myChart = this.$echarts.init(this.$refs.myChart);
        var option = {
          title: [{
              text: '紧急求助处理',
              x: 'center',
              top: '55%',
              textStyle: {
                color: '#FFFFFF',
                fontSize: 8,
                fontWeight: '100',
              },
            },
            {
              text: this.messagE,
              x: 'center',
              y: 'center',
              textStyle: {
                fontSize: '16',
                color: '#FFFFFF',
                fontFamily: 'DINAlternate-Bold, DINAlternate',
                foontWeight: '600',
              },
            },
          ],
          polar: {
            radius: ['42%', '52%'],
            center: ['50%', '50%'],
          },
          angleAxis: {
            max: 100,
            show: false,
          },
          radiusAxis: {
            type: 'category',
            show: true,
            axisLabel: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
          },
          series: [{
              name: '',
              type: 'bar',
              roundCap: true,
              barWidth: 30,
              radius: ['100%'],
              showBackground: true,
              backgroundStyle: {
                color: 'rgba(66, 66, 66, .3)',
              },
              data: [this.messagE],
              coordinateSystem: 'polar',
              itemStyle: {
                normal: {
                  color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                      offset: 0,
                      color: '#16CEB9',
                    },
                    {
                      offset: 1,
                      color: '#6648FF',
                    },
                  ]),
                },
              },
            },
            {
              name: '',
              type: 'pie',
              startAngle: 80,
              radius: ['56%'],
              hoverAnimation: false,
              center: ['50%', '50%'],
              itemStyle: {
                color: 'rgba(66, 66, 66, .1)',
                borderWidth: 1,
                borderColor: '#5269EE',
              },
              data: [100],
            }
          ],
        };
        myChart.clear();
        myChart.setOption(option, true);
      },
      message() {
        let Authorization = window.sessionStorage.getItem("Authorization");
        this.$axios.get('/message/selectMessageCount?state=' + 9, {
          headers: {
            'token': Authorization
          }
        }).then((res) => {
          if (res.data.code == 401) {
            this.$router.push('/')
              
          } else {
            this.messagE = res.data.data
            this.getEchartData()
          }

        })
      }
    },
    mounted() {
      this.getEchartData()
      this.message()
      this.Timer = setInterval(this.message, 4000)
    },
    destroyed() {
      clearInterval(this.Timer)
    }
  }
</script>

<style scoped>
  .contontaab {
    z-index: 999;
    width: 6rem;
    height: 5rem;
    position: relative;
    top: 1px;
    left: -40px;
  }
</style>