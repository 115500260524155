<template>
  <div class="contontaab">
    <div :style="{ width: '200px', height: '180px' }" ref="myChart"></div>
  </div>
</template>
<script>
  import {
    setInterval
  } from 'core-js/web';
  export default {
    data() {
      return {
        yearRevenue: '', //本年充值
        newMonth: '', //本月新增
        todayRevenue: '', //今日重铸
        totalRevenue: '', //总充值
        monthRevenue: '', //本月充值
        leida: '',
        Timer: ''
      }
    },
    methods: {
      getEchartData() {
        let myChart = this.$echarts.init(this.$refs.myChart);
        var option = {
          color: ['#66e7f9', '#e74b4a', '#ffb534', "#009AB0", '#c67a4a'],
          legend: {
            top: '10px',
            icon: 'circle',
            textStyle: {
              color: '#fff'
            },
            itemWidth: 5,
            itemHeight: 5
          },
          tooltip: {
            confine: true,
            trigger: 'item',
            formatter: '{b} : {c}'
          },
          series: [{
            avoidLabelOverlap: false,
            type: 'pie',
            top: '30px',
            roseType: 'area', // 玫瑰图
            center: ['50%', '50%'],
            radius: ['20%', '50%'],
            label: {
              normal: {
                formatter: '{c}元',
                textStyle: {
                  fontSize: 10
                }
              }
            },
            labelLine: {
              normal: {
                length: 5,
                length2: 5,
                length3: 5,
                length4: 5,
                length5: 5
              }
            },
            data: [{
              name: '本年充值',
              value: this.yearRevenue
            }, {
              name: '本月新增',
              value: this.newMonth
            }, {
              name: '今日充值',
              value: this.todayRevenue
            }, {
              name: '总充值',
              value: this.totalRevenue
            }, {
              name: '本月充值',
              value: this.monthRevenue
            }]
          }]
        };
        let currentIndex = 0;
        clearInterval(this.timer);
        this.timer = setInterval(function() {
          // 取消之前高亮的图形
          myChart.dispatchAction({
            type: 'downplay',
            seriesIndex: 0,
          });
          // 高亮当前图形
          myChart.dispatchAction({
            type: 'highlight',
            seriesIndex: 0,
            dataIndex: currentIndex
        
          });
          // 显示 tooltip
          myChart.dispatchAction({
            type: 'showTip',
            seriesIndex: 0,
            dataIndex: currentIndex
        
          });
          currentIndex++;
          if (currentIndex > option.series[0].data.length) {
            currentIndex = 0;
          }
        }, 2000);
        myChart.clear()
        myChart.setOption(option, true);
      },
      gethomeMessage() {
        let Authorization = window.sessionStorage.getItem("Authorization");
        this.$axios.get("/user/homeMessage", {
          headers: {
            'token': Authorization
          }
        }).then((res) => {
          if (res.data.code == 401) {
            this.$router.push('/')
              
          } else {
            this.yearRevenue = res.data.data.yearRevenue, //本年充值
              this.newMonth = res.data.data.newMonth, //本月新增
              this.todayRevenue = res.data.data.todayRevenue, //今日重铸
              this.totalRevenue = res.data.data.totalRevenue, //总充值
              this.monthRevenue = res.data.data.monthRevenue //本月充值
            this.getEchartData()
          }

        })
      }
    },
    mounted() {
      this.getEchartData()
      this.gethomeMessage()
      this.Timer = setInterval(this.gethomeMessage, 20000)
    },
    destroyed() {
      clearInterval(this.Timer)
    }
  }
</script>

<style scoped>
  .contontaab {
    z-index: 999;
    width: 100%;
    height: 100%;
    position: relative;
    left: 40px;
    top: 20px;
  }
</style>